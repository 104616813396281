import React from 'react';
import classnames from 'classnames';
import './styles.scss';

export enum AlertType {
  INFO = 'info',
  WARNING = 'warning',
}

export type AlertProps = {
  actionText?: string;
  link?: React.ReactNode;
  message?: string;
  onClick?: VoidFunction;
  type?: AlertType;
};

const Alert = ({
  actionText,
  link,
  message = '',
  onClick,
  type = AlertType.INFO,
}: AlertProps) => {
  const displayActionText = actionText && onClick;
  const displayLink = !!link;
  return (
    <div className={classnames('page-alert', `alert-${type}`)}>
      <p>{message}</p>
      {displayActionText && <span onClick={onClick}>{actionText}</span>}
      {displayLink && link}
    </div>
  );
};

export default Alert;
