import React, { useCallback, useEffect, useState } from 'react';
import { createActions } from '../../../../redux/admin/create/actions';
import { editActions } from '../../../../redux/admin/edit/actions';
import { isPublishedExpo } from '../../../common/utilities/expo';
import { ExpoEventTypes, ExpoStates } from '../../../entities/expos/constants';
import { IExpoBase } from '../../../entities/expos/types';
import {
  backgroundImagesOptions,
  CUSTOM_IMAGE_VALUE,
} from '../../../entities/expos/utilities';
import { GeneralForm } from './index';
import useGeneralFormState from './state';

export type GeneralFormContainerProps = {
  expoState?: keyof typeof ExpoStates | undefined;
  initialValues: IExpoBase;
  isCapacityChecked?: boolean;
  isEndDateExtended?: boolean;
  isNewExpo: boolean;
  onFormValidityChange: (valid: boolean) => void;
  resetCheckCapacity?: typeof editActions.checkCapacityReset;
  updateFields:
    | typeof editActions.onFieldUpdate
    | typeof createActions.updateExpo;
};

export const GeneralFormContainer = ({
  expoState,
  initialValues,
  isCapacityChecked,
  isEndDateExtended,
  isNewExpo,
  onFormValidityChange,
  resetCheckCapacity,
  updateFields,
}: GeneralFormContainerProps) => {
  const {
    form,
    updateDateFields,
    updateExpoType,
    updateField,
    updateBackgroundImage,
    values,
  } = useGeneralFormState(initialValues, onFormValidityChange, updateFields);

  const backgroundImageSelectValue = useCallback(
    (backgroundImage: string): string => {
      const images = backgroundImagesOptions(form.type.value as ExpoEventTypes);
      if (images.find((image) => image.value === backgroundImage)) {
        return backgroundImage;
      }
      return CUSTOM_IMAGE_VALUE;
    },
    [form.type],
  );

  const [backgroundImageSelect, setBackgroundImageSelect] = useState<string>(
    backgroundImageSelectValue(values.backgroundImage),
  );
  const [showCustomImageField, setShowCustomImageField] = useState<boolean>(
    backgroundImageSelect === CUSTOM_IMAGE_VALUE,
  );

  const setBackgroundImageSelectFormState = (image: string): void => {
    setBackgroundImageSelect(image);
    setShowCustomImageField(image === CUSTOM_IMAGE_VALUE);
  };

  const updateSelectedExpoType = (
    name: string,
    value: ExpoEventTypes,
  ): void => {
    updateExpoType(name, value);
    setBackgroundImageSelectFormState('');
  };

  const updateSelectedBackgroundImage = (name: string, value: string): void => {
    updateBackgroundImage(name, value);
    const selectedValue = backgroundImageSelectValue(value);
    setBackgroundImageSelectFormState(selectedValue);
  };

  const isPublished = isPublishedExpo(expoState);
  const isFormEndDateChanged = values.end !== initialValues.end;
  const isCapacityCheckedForExtension =
    isPublished && isEndDateExtended && !isCapacityChecked;

  useEffect(() => {
    if (isPublished && resetCheckCapacity && isFormEndDateChanged) {
      resetCheckCapacity();
    }
  }, [isPublished, resetCheckCapacity, isFormEndDateChanged]);

  return (
    <GeneralForm
      backgroundImageSelect={backgroundImageSelect}
      form={form}
      isCapacityCheckedForExtension={isCapacityCheckedForExtension}
      isNewExpo={isNewExpo}
      isPublished={isPublished}
      showCustomImageField={showCustomImageField}
      updateField={updateField}
      updateDateFields={updateDateFields}
      updateSelectedBackgroundImage={updateSelectedBackgroundImage}
      updateSelectedExpoType={updateSelectedExpoType}
      values={values}
    />
  );
};
