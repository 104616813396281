import React, { ReactElement, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { convertDateToString } from '../../utilities/helpers';
import {
  DateRangePicker as DateRange,
  FocusedInputShape,
  DisabledShape,
} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';

type DateRangeProps = {
  endDate: moment.Moment | null;
  startDate: moment.Moment | null;
};

export type DateRangePickerProps = {
  disabled?: DisabledShape;
  disableToday?: boolean;
  endDate: moment.Moment | null;
  endDateName: string;
  onDateRangeChange(values: { [key: string]: string }): void;
  startDate: moment.Moment | null;
  startDateName: string;
};

const DateRangePicker = ({
  disabled = false,
  disableToday,
  endDate,
  endDateName,
  onDateRangeChange,
  startDate,
  startDateName,
}: DateRangePickerProps): ReactElement => {
  const didMount = useRef(false);
  const [dateRange, setDateRange] = useState({ endDate, startDate });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null,
  );

  const onFocusChange = (focusedInput: FocusedInputShape | null) => {
    setFocusedInput(focusedInput);
  };

  const onDateSelect = (dates: DateRangeProps) => {
    setDateRange({ endDate: dates.endDate, startDate: dates.startDate });
  };

  const isOutsideRange = (day: moment.Moment) => {
    const today = moment();
    if (disableToday) {
      return day.isSameOrBefore(today, 'day');
    }
    return day.isBefore(today, 'day');
  };

  useEffect(() => {
    const endDate = convertDateToString(dateRange.endDate);
    const startDate = convertDateToString(dateRange.startDate);
    if (didMount.current) {
      onDateRangeChange({
        [endDateName]: endDate,
        [startDateName]: startDate,
      });
    } else {
      didMount.current = true;
    }
  }, [dateRange]);

  return (
    <DateRange
      disabled={disabled}
      endDate={dateRange.endDate}
      endDateId={endDateName}
      focusedInput={focusedInput}
      minimumNights={0}
      startDate={dateRange.startDate}
      startDateId={startDateName}
      onDatesChange={onDateSelect}
      onFocusChange={onFocusChange}
      isOutsideRange={isOutsideRange}
    />
  );
};

export default DateRangePicker;
