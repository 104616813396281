import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { editActions } from '../../../redux/admin/edit/actions';
import Layout from '../../common/components/layout';
import { isPublishedExpo } from '../../common/utilities/expo';
import { MAIN_BACKGROUND_IMAGE } from '../../common/utilities/imageAssets';
import { ExpoStates } from '../../entities/expos/constants';
import { IExpoBase, ISavedExpo } from '../../entities/expos/types';
import { isExpoTypeInvalid } from '../../entities/expos/utilities';
import { GeneralFormContainer } from '../components/general-form/container';
import Header from '../components/header';
import Content from './components/content/container';
import { returnGeneralFormFields } from './utilities';

export interface EditProps extends RouteComponentProps<{ id: string }> {
  disablePublish: boolean;
  disableSave: boolean;
  expo: ISavedExpo | null;
  fetchExpo: typeof editActions.fetchExpo;
  isCapacityChecked: boolean;
  isEndDateExtended: boolean;
  onFieldUpdate: typeof editActions.onFieldUpdate;
  reset: typeof editActions.reset;
  resetCheckCapacity: typeof editActions.checkCapacityReset;
  saveExpo: typeof editActions.saveExpo;
  updateGeneralFormValidity: typeof editActions.updateGeneralFormValidity;
}

const Edit = ({
  expo,
  disablePublish,
  disableSave,
  fetchExpo,
  isEndDateExtended,
  isCapacityChecked,
  match,
  onFieldUpdate,
  reset,
  resetCheckCapacity,
  saveExpo,
  updateGeneralFormValidity,
}: EditProps): ReactElement | null => {
  const generalFormValues: IExpoBase = React.useMemo(
    () => returnGeneralFormFields(expo),
    [expo],
  );

  const { id } = match.params;

  React.useEffect(() => {
    fetchExpo(id);
  }, [id, fetchExpo]);

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (!expo) {
    return null;
  }

  const expoState: keyof typeof ExpoStates = expo.state;
  const isPublished = isPublishedExpo(expoState);

  if (isExpoTypeInvalid(generalFormValues.type)) {
    generalFormValues.type = '';
    generalFormValues.backgroundImage = '';
  }

  return (
    <div data-testid="edit">
      <Header
        onSave={saveExpo}
        disableSave={disableSave}
        disablePublish={disablePublish}
        showPublish={!!expo._links.publish}
        uid={id}
      />
      <Layout
        url={
          isExpoTypeInvalid(generalFormValues.type)
            ? MAIN_BACKGROUND_IMAGE
            : expo.backgroundImage
        }
      >
        <GeneralFormContainer
          expoState={expoState}
          initialValues={generalFormValues}
          isCapacityChecked={isCapacityChecked}
          isEndDateExtended={isEndDateExtended}
          isNewExpo={false}
          onFormValidityChange={updateGeneralFormValidity}
          resetCheckCapacity={resetCheckCapacity}
          updateFields={onFieldUpdate}
        />
        <Content
          isPublished={isPublished}
          showCheckCapacity={!!expo._links.capacity}
        />
      </Layout>
    </div>
  );
};

export default Edit;
