import {
  CHECK_CAPACITY_SUCCESS,
  Actions,
  CHECK_CAPACITY_FAILED,
  VALIDATE_CSV_SUCCESS,
  VALIDATE_CSV_SUCCESS_WITH_ERRORS,
  CHECK_CAPACITY_RESET,
} from '../../actions';

export interface ICapacityAvailableState {
  capacityAvailable: boolean;
  capacityChecked: boolean;
  capacityDetail: IFormattedCapacityContent[];
}

export const initialState: ICapacityAvailableState = {
  capacityAvailable: true,
  capacityChecked: false,
  capacityDetail: [],
};

export default function capacityAvailableReducer(
  state: ICapacityAvailableState = initialState,
  action: Actions,
): ICapacityAvailableState {
  switch (action.type) {
    case CHECK_CAPACITY_SUCCESS: {
      return {
        ...state,
        capacityChecked: true,
        capacityAvailable: true,
        capacityDetail: action.payload,
      };
    }
    case VALIDATE_CSV_SUCCESS:
    case VALIDATE_CSV_SUCCESS_WITH_ERRORS:
      return {
        ...state,
        capacityChecked: false,
        capacityAvailable: true,
        capacityDetail: [],
      };

    case CHECK_CAPACITY_FAILED:
      return {
        ...state,
        capacityChecked: true,
        capacityAvailable: false,
        capacityDetail: action.payload,
      };

    case CHECK_CAPACITY_RESET:
      return {
        ...state,
        capacityChecked: false,
      };

    default:
      return state;
  }
}
