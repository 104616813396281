import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { infoActions } from '../../../redux/admin/info/actions';
import { modalActions } from '../../../redux/modals/actions';
import Layout from '../../common/components/layout';
import { MODAL_NAMES } from '../../common/components/modal/constants';
import { ROUTES } from '../../common/constants';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
} from '../../common/pov-common-ui';
import { MAIN_BACKGROUND_IMAGE } from '../../common/utilities/imageAssets';
import { ISavedExpo } from '../../entities/expos/types';
import { Header } from '../components/header';
import Content from './components/content/container';
import Engagements from './components/engagements/container';
import Rebalance from './components/rebalance/container';
import './styles.scss';

export interface InfoProps extends RouteComponentProps<{ id: string }> {
  expo?: ISavedExpo;
  openModal: typeof modalActions.openModal;
  fetchDemos: typeof infoActions.getExpoDemos;
}

const Info = ({
  expo,
  fetchDemos,
  match,
  openModal,
}: InfoProps): ReactElement => {
  const expoUid = match.params.id;
  const expoName = expo?.name;
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchDemos(expoUid);
  }, [expoUid, fetchDemos]);

  return (
    <>
      <Header showSave={false} />
      <Layout url={expo!.backgroundImage || MAIN_BACKGROUND_IMAGE}>
        <Card>
          <CardHeader title={expo!.name} alignTitle="center" />
          <CardContent>
            <div className="manage-expo-btns">
              <Button
                onClick={() =>
                  openModal(MODAL_NAMES.END_EXPO, { expoUid, expoName })
                }
                className="end-expo-btn"
                color="secondary"
                id="endExpo"
              >
                {t('admin.info.endExpo')}
              </Button>
            </div>
            <ul role="tablist" className="tabs info-tabs">
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_CONTENT.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  {t('admin.info.content')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_ENGAGEMENTS.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  {t('admin.info.users')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact={true}
                  to={ROUTES.ADMIN_VIEW_SESSION_INFO_REBALANCE.replace(
                    ':id',
                    expoUid,
                  )}
                  activeClassName="active"
                >
                  {t('admin.info.rebalance')}
                </NavLink>
              </li>
            </ul>

            <Switch>
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_CONTENT.replace(
                  ':id',
                  expoUid,
                )}
                render={() => <Content />}
              />
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_ENGAGEMENTS.replace(
                  ':id',
                  expoUid,
                )}
                component={() => <Engagements expoUid={expoUid} />}
              />
              <Route
                exact={true}
                path={ROUTES.ADMIN_VIEW_SESSION_INFO_REBALANCE.replace(
                  ':id',
                  expoUid,
                )}
                component={() => <Rebalance expoUid={expoUid} />}
              />
            </Switch>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};

export default Info;
