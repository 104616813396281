import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Timezone } from 'react-timezone';
import TimezoneInput from '../../../common/components/time-zone-input';
import { Config, OnChange } from '../../../common/hooks/useFormValidation';
import {
  Card,
  CardHeader,
  DateRangePicker,
  Dropdown,
  FormGroup,
  Label,
  TextArea,
  TextInput,
} from '../../../common/pov-common-ui';
import Alert, { AlertType } from '../../../common/pov-common-ui/Alert/Alert';
import { ExpoEventTypes } from '../../../entities/expos/constants';
import { IExpoBase } from '../../../entities/expos/types';
import {
  backgroundImagesOptions,
  expoTypeOptions,
} from '../../../entities/expos/utilities';
import EngagementDurationControls from '../engagement-duration-controls';
import FeedbackSurveyManagement from '../feedback-survey-management/container';
import { IDates } from './state';
import './styles.scss';

export type GeneralFormProps = {
  backgroundImageSelect?: string | undefined;
  form: Config<IExpoBase, OnChange>;
  isCapacityCheckedForExtension?: boolean;
  isNewExpo: boolean;
  isPublished: boolean;
  showCustomImageField: boolean;
  updateField: (name: string, value: any) => void;
  updateDateFields: (dates: IDates) => void;
  updateSelectedBackgroundImage: (name: string, value: string) => void;
  updateSelectedExpoType: (name: string, value: ExpoEventTypes) => void;
  values: IExpoBase;
};

export const GeneralForm = ({
  backgroundImageSelect,
  form,
  isCapacityCheckedForExtension,
  isNewExpo,
  isPublished,
  showCustomImageField,
  updateField,
  updateDateFields,
  updateSelectedBackgroundImage,
  updateSelectedExpoType,
  values,
}: GeneralFormProps) => {
  const { t } = useTranslation();
  return (
    <div className="general-form">
      <Card>
        <CardHeader title="General" alignTitle="center" />
        {isCapacityCheckedForExtension && (
          <Alert
            type={AlertType.WARNING}
            link={
              <a href="#check-capacity">
                {t('admin.edit.alert.checkCapacity')}
              </a>
            }
            message={t('admin.edit.alert.endDateExtend')}
          />
        )}
        <div className="section">
          <div className="row">
            <div className="col-md-4">
              <TextInput {...form.name} label="Name" required={true} />
            </div>
            <div className="col-md-2">
              <FormGroup inline={true} errors={form.type.errors}>
                <Label labelFor="type">Type</Label>
                <Dropdown
                  {...form.type}
                  onChange={updateSelectedExpoType}
                  options={expoTypeOptions()}
                  selected={values.type ?? ''}
                />
              </FormGroup>
            </div>
            <div className="col-md-2">
              <FormGroup
                inline={true}
                errors={
                  values.type && !showCustomImageField
                    ? form.backgroundImage.errors
                    : []
                }
              >
                <Label labelFor="backgroundImageSelect">Background Image</Label>
                <Dropdown
                  id="backgroundImageSelect"
                  name="backgroundImageSelect"
                  disabled={!values.type}
                  onChange={updateSelectedBackgroundImage}
                  options={backgroundImagesOptions(
                    form.type.value as ExpoEventTypes,
                  )}
                  selected={backgroundImageSelect ?? ''}
                  fixedHeight={true}
                />
              </FormGroup>
            </div>
            <div className="col-md-4">
              <FormGroup errors={[...form.end.errors, ...form.start.errors]}>
                <Label labelFor="dates">Dates</Label>
                <DateRangePicker
                  disabled={isPublished ? 'startDate' : false}
                  endDate={values.end ? moment(values.end) : null}
                  endDateName="end"
                  onDateRangeChange={updateDateFields}
                  startDate={values.start ? moment(values.start) : null}
                  startDateName="start"
                />
              </FormGroup>
            </div>
          </div>
          {showCustomImageField && (
            <div className="row">
              <div className="col-md-12">
                <TextInput
                  {...form.backgroundImage}
                  label="Custom Background Image URL"
                  required={true}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-4">
              <TextInput {...form.description} label="Description" />
            </div>
            <div className="col-md-2">
              <TextInput
                {...form.noOfSeats}
                disabled={isPublished}
                required={true}
                label="Seats"
                type="number"
                value={form.noOfSeats.value as string}
              />
            </div>
            <div className="col-md-2">
              <TextInput {...form.location} label="Location" required={true} />
            </div>
            <div className="col-md-4">
              <TimezoneInput
                {...form.timezone}
                disabled={isPublished}
                onChange={(timezone: Timezone) => {
                  updateField('timezone', timezone);
                }}
              />
            </div>
          </div>
          <EngagementDurationControls
            isNewExpo={isNewExpo}
            maxExtensionFormConfig={form.maxEngagementExtensionInMin}
            timeoutFormConfig={form.engagementTimeoutInMin}
            timeoutWarningFormConfig={form.engagementTimeoutWarningInMin}
            updateField={updateField}
            values={values}
          />
          <FeedbackSurveyManagement
            fieldOption={form.feedbackOption}
            fieldUrl={form.feedbackUrl}
            updateField={updateField}
            values={values}
          />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <TextArea
              {...form.gettingStarted}
              label="Getting Started"
              maxCharCount={500}
              required={true}
              rows={5}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
