import { push } from 'connected-react-router';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ROUTES } from '../../../../../app/common/constants';
import { ToastSeverity } from '../../../../../app/common/pov-common-ui';
import { terminateExpo } from '../../../../../app/entities/expos/service';
import i18n from '../../../../../i18n';
import { IActionWithPayload } from '../../../../action-helpers';
import { modalActions } from '../../../../modals/actions';
import { toastActions } from '../../../../toast/actions';
import { END_EXPO } from '../../actions';

export default function* endExpoWorker(
  action: IActionWithPayload<
    typeof END_EXPO,
    { uid: string; expoName: string }
  >,
): SagaIterator {
  try {
    const { uid, expoName } = action.payload;
    yield put(modalActions.modalLoading(true));
    yield call(terminateExpo, uid);
    yield put(push(ROUTES.ADMIN));
    yield put(
      toastActions.showToast(
        i18n.t('components.modal.endExpo.messages.success.title'),
        i18n.t('components.modal.endExpo.messages.success.content', {
          expoName,
        }),
        ToastSeverity.SUCCESS,
      ),
    );
  } catch (error) {
    yield put(
      toastActions.showToast(
        i18n.t('components.modal.endExpo.messages.error.title'),
        error.response.data[0].message,
        ToastSeverity.DANGER,
      ),
    );
  } finally {
    yield put(modalActions.closeModal());
    yield put(modalActions.modalLoading(false));
  }
}
