import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { FormGroup } from '../FormGroup';
import './styles.scss';

export interface TextAreaProps extends ISharedInputProps {
  disabled?: boolean;
  errors?: string[];
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  maxCharCount?: number;
  value?: string;
}

export const calculateCharacterLength = (max: number, value = ''): number => {
  const len: number = value.length;
  if (!len) return max;
  if (len > max) return 0;
  return max - len;
};

const TextArea = ({
  disabled = false,
  errors = [],
  hideLabel = false,
  id,
  label,
  name,
  maxCharCount,
  onChange,
  placeholder,
  required = false,
  rows = 5,
  value,
}: TextAreaProps): ReactElement => {
  const classes = classNames('sc-text-area', {
    'sc-text-area__with-count': !!maxCharCount,
  });

  return (
    <FormGroup className={classes} withFormGroupText={true} errors={errors}>
      <textarea
        disabled={disabled}
        value={value}
        name={name}
        id={id}
        rows={rows}
        placeholder={placeholder}
        onBlur={onChange}
        onChange={onChange}
        className="textarea"
      />
      <label
        htmlFor={name}
        className={classNames('text-area__label', { 'sr-only': hideLabel })}
      >
        {label}
        {required && <span>*</span>}
      </label>
      {maxCharCount && (
        <p className="sc-text-area__count">
          *{calculateCharacterLength(maxCharCount, value)} Characters remaining
        </p>
      )}
    </FormGroup>
  );
};

export default TextArea;
