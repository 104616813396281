import { AxiosResponse } from 'axios';
import api, { returnResponseData } from '../../../../api/kapua';
import { ExpoDateFilters } from '../constants';
import {
  IAccessCode,
  ICheckCapacityRequestParams,
  IDataCenter,
  IDataCentersResponse,
  IDataCenterUpdateRequest,
  IExpoCreateRequest,
  IExpoEditRequest,
  IExpoResponse,
  IPublicExpoResponse,
  ISurveyType,
} from '../types';

interface IEmbedded<T> {
  _embedded: T;
}

interface IPostEngagementPayload {
  email: string;
  termsAndConditionsAccepted: boolean;
}

export enum URLS {
  ACCESS_CODES = '/access-codes',
  BASE = '/expos',
  CHECK_CAPACITY = '/:id/capacity',
  CONTENT_TRANSFORMATIONS = '/content-transformations',
  DATACENTERS = '/datacenters',
  DEMOS = '/demos',
  ENGAGEMENTS = '/engagements',
  EXTEND = '/public/extensions',
  METADATA = '/metadata',
  PUBLIC = '/public',
  PUBLICATIONS = '/publications',
  SURVEY_TYPES = '/survey-types',
  TERMINATIONS = '/terminations',
  UPDATE_TARGET_COUNT = '/expos/:id/deltas',
}

export const checkCapacity = (
  uid: string,
  params: ICheckCapacityRequestParams = {},
): Promise<ICapacityContent> =>
  api
    .get(`${URLS.BASE}${URLS.CHECK_CAPACITY.replace(':id', uid)}`, {
      params,
    })
    .then((response) => response.data.content);

export const createExpo = (expo: IExpoCreateRequest): Promise<IExpoResponse> =>
  api.post(URLS.BASE, expo).then(returnResponseData);

export const deleteExpo = (uid: string): Promise<AxiosResponse> =>
  api.delete(`${URLS.BASE}/${uid}`);

export const endExpoDemoEngagement = (uid: string): Promise<AxiosResponse> =>
  api.delete(`${URLS.PUBLIC}${URLS.ENGAGEMENTS}/${uid}`);

export const extendEngagement = (uid: string): Promise<AxiosResponse> =>
  api.post(`${URLS.EXTEND}`, { engagement: { uid } });

export const getAccessCode = (uid: string): Promise<IAccessCode> =>
  api
    .get(`${URLS.BASE}/${uid}/access-code`)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });

export const getDataCenter = (
  uid: string,
): Promise<AxiosResponse<IDataCenter>> => api.get(`${URLS.DATACENTERS}/${uid}`);

export const getDataCenters = (): Promise<IDataCenter[]> =>
  api
    .get<IEmbedded<IDataCentersResponse>>(URLS.DATACENTERS)
    .then((response) => response.data._embedded.datacenters);

export const getExpoByAccessCode = (accessCode: string): Promise<string> =>
  api
    .get<{ expo: { uid: string } }>(
      `${URLS.PUBLIC}${URLS.ACCESS_CODES}/${accessCode}`,
    )
    .then((response) => response.data.expo.uid);

export const getExpoDemoEngagement = (uid: string): Promise<IDemoEngagement> =>
  api
    .get<IDemoEngagement>(`${URLS.PUBLIC}${URLS.ENGAGEMENTS}/${uid}`)
    .then((response) => response.data);

export function getExpos(
  order = ExpoDateFilters.DESC,
): Promise<IExpoResponse[]> {
  const query = `?sort=start,${order}`;

  return api
    .get<IEmbedded<{ expos: IExpoResponse[] }>>(`${URLS.BASE}${query}`)
    .then((response) => response.data._embedded.expos);
}

export const getPublicExpo = (uid: string): Promise<IPublicExpoResponse> =>
  api
    .get<IPublicExpoResponse>(`${URLS.PUBLIC}${URLS.BASE}/${uid}`)
    .then((response) => response.data);

export const getSingleExpo = (
  uid: string,
): Promise<AxiosResponse<IExpoResponse>> => api.get(`${URLS.BASE}/${uid}`);

export const getSingleExpoDemos = (uid: string): Promise<IDemoDetails[]> =>
  api
    .get(`${URLS.BASE}/${uid}${URLS.DEMOS}`)
    .then((response) => response.data._embedded.demos);

export const getSingleExpoEngagements = (
  uid: string,
): Promise<IEngagementDetails[]> =>
  api
    .get(`${URLS.BASE}/${uid}${URLS.ENGAGEMENTS}`)
    .then((response) => response.data._embedded.engagements);

export const getSurveyTypes = (): Promise<ISurveyType[]> =>
  api
    .get<IEmbedded<{ surveyTypes: ISurveyType[] }>>(
      `${URLS.METADATA}${URLS.SURVEY_TYPES}`,
    )
    .then((response) => response.data._embedded.surveyTypes);

export const postEngagement = (
  demoUid: string,
  payload: IPostEngagementPayload,
): Promise<string> =>
  api
    .post<{ uid: string }>(`${URLS.PUBLIC}${URLS.ENGAGEMENTS}`, {
      demo: { uid: demoUid },
      ...payload,
    })
    .then((response) => response.data.uid);

export const postVerifyContent = (content: string): Promise<DemoContent> =>
  api
    .post<{ transformedContent: DemoContent }>(URLS.CONTENT_TRANSFORMATIONS, {
      content,
    })
    .then((response) => response.data.transformedContent);

export const publications = (uid: string): Promise<AxiosResponse> =>
  api.post(URLS.PUBLICATIONS, { expo: { uid } });

export const terminateExpo = (uid: string): Promise<AxiosResponse> =>
  api.post(URLS.TERMINATIONS, { expo: { uid } });

export const unpublishExpo = (uid: string): Promise<AxiosResponse> =>
  api.delete(`${URLS.PUBLICATIONS}/${uid}`);

export const updateDataCenter = (
  dataCenterRequest: IDataCenterUpdateRequest,
  etag: string,
): Promise<IDataCenter> => {
  const config = {
    headers: {
      'If-match': etag,
    },
  };

  return api
    .put<IDataCenter>(
      `${URLS.DATACENTERS}/${dataCenterRequest.uid}`,
      dataCenterRequest,
      config,
    )
    .then((response) => response.data);
};

export const updateExpo = (
  expo: IExpoEditRequest,
  ifMatch: string,
): Promise<AxiosResponse<IExpoResponse>> => {
  const config = {
    headers: {
      'If-match': ifMatch,
    },
  };

  return api.put(`${URLS.BASE}/${expo.uid}`, expo, config);
};

export const updateTargetCount = (id: string, data: {}) =>
  api.post(URLS.UPDATE_TARGET_COUNT.replace(':id', id), { ...data });
