const en = {
  admin: {
    dataCenterConnection: 'Data Center Connection',
    info: {
      content: 'Content',
      endExpo: 'End eXpo',
      rebalance: 'Rebalance',
      users: 'Users',
    },
    dataCenters: {
      modal: {
        connect: {
          title: 'Connect {{dataCenter}}',
          message:
            'This action will affect all demos in all eXpos utilizing this data center, making them available to users',
          button: 'Connect',
        },
        disconnect: {
          title: 'Disconnect {{dataCenter}}',
          message:
            'This action will affect all demos in all eXpos utilizing this data center, making them unavailable to users',
          button: 'Disconnect',
        },
        cancel: 'Cancel',
      },
      dataCenterDisconnected: 'Data Center Disconnected',
    },
    edit: {
      alert: {
        checkCapacity: 'Check Capacity',
        endDateExtend:
            "The end date has been extended. You must 'Check Capacity' before you can save your changes.",
      },
      addContentForm: {
        helpText: {
          addContent:
              'To add content please use the following syntax, putting each entry on a new line.',
          scheduled:
              'For SCHEDULED: fields representing DEMO_ID, DATACENTER, CATEGORY, DEMO_TYPE(S), SESSIONS, CONTENT POOL (Optional) e.g. 3442356, RTP, Security, S, 30, My Selected Pool ',
          instant:
              'For INSTANT: fields representing HANDLE, DATACENTER, CATEGORY, DEMO_TYPE(I) e.g. cisco-duo-v1, RTP, Security, I',
        },
        label: 'Add Content',
        validate: 'Validate',
      },
    },
  },
  cardButtons: {
    accessCode: 'Access Code',
    edit: 'Edit',
    manage: 'Manage',
    unpublish: 'Unpublish',
  },
  components: {
    modal: {
      buttons: {
        cancel: 'Cancel',
        end: 'End',
      },
      endExpo: {
        title: 'End eXpo',
        content: {
          note: 'By ending the eXpo before its scheduled end date, all sessions and user engagements will be terminated. Once ended, this action cannot be reversed.',
          question: 'Are you sure you want to End this eXpo?',
        },
        messages: {
          success: {
            title: 'Success',
            content: "eXpo '{{expoName}}' has been ended",
          },
          error: {
            title: 'Error',
          },
        },
      },
    },
    timezoneInput: {
      timezone: 'Timezone',
    },
  },
  dCloudLogo: {
    topLine: 'Labs brought to you by',
    bottomLine: 'Cisco dCloud',
  },
  manage: {
    rebalanceControls: {
      applyChanges: 'Apply Changes',
    },
  },
  publishExpo: {
    cancel: 'Cancel',
    publish: 'Publish',
    title: 'Publish',
    content: {
      note: 'Please note that documentation for demos associated to an eXpo are directly accessible via the public Internet whilst the eXpo is running. If your eXpo contains restricted content such as non-FCS code, please contact the dCloud Support Team before publishing the eXpo.',
      question: 'Are you sure you want to publish this eXpo?',
    },
  },
};

export default en;
