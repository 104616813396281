import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextArea } from '../../../../../common/pov-common-ui';

export type AddContentFormProps = {
  csvContent?: string;
  csvErrors: string[];
  disableContentInput?: boolean;
  disableValidate: boolean;
  setCsvValue: (value: string) => void;
  validate: () => void;
};

const AddContentForm = ({
  csvContent = '',
  csvErrors,
  disableContentInput = false,
  disableValidate,
  setCsvValue,
  validate,
}: AddContentFormProps): ReactElement => {
  const { t } = useTranslation();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    validate();
  };

  useEffect(() => {
    if (disableContentInput) {
      validate();
    }
  }, [disableContentInput, validate]);

  return (
    <form className="content-card__form" onSubmit={onSubmit}>
      <TextArea
        disabled={disableContentInput}
        errors={csvErrors}
        label={t('admin.edit.addContentForm.label')}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setCsvValue(e.target.value);
        }}
        name="addContent"
        id="addContent"
        value={csvContent}
      />
      <div className="help-block text-muted">
        <span>
          <p>{t('admin.edit.addContentForm.helpText.addContent')}</p>
          <p>{t('admin.edit.addContentForm.helpText.scheduled')}</p>
          <p>{t('admin.edit.addContentForm.helpText.instant')}</p>
        </span>
      </div>
      <Button
        color="primary"
        id="validate"
        type="submit"
        disabled={disableValidate}
      >
        {t('admin.edit.addContentForm.validate')}
      </Button>
    </form>
  );
};

export default AddContentForm;
