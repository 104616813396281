import React, { ReactElement } from 'react';
import { createActions } from '../../../redux/admin/create/actions';
import Layout from '../../common/components/layout';
import { MAIN_BACKGROUND_IMAGE } from '../../common/utilities/imageAssets';
import { IExpoBase } from '../../entities/expos/types';
import { GeneralFormContainer } from '../components/general-form/container';
import Header from '../components/header';

export interface IProps {
  createExpo: typeof createActions.createExpo;
  formIsValid: boolean;
  expo: IExpoBase;
  onFieldUpdate: typeof createActions.updateExpo;
  setFormValidity: typeof createActions.setFormValidity;
}

export default function Create({
  createExpo,
  formIsValid,
  setFormValidity,
  expo,
  onFieldUpdate,
}: IProps): ReactElement {
  return (
    <>
      <Header onSave={() => createExpo()} disableSave={!formIsValid} />
      <Layout url={expo.backgroundImage || MAIN_BACKGROUND_IMAGE}>
        <GeneralFormContainer
          initialValues={expo}
          isNewExpo={true}
          onFormValidityChange={setFormValidity}
          updateFields={onFieldUpdate}
        />
      </Layout>
    </>
  );
}
